import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

// import { Main } from 'styles/blogPost';
import Meta from 'components/Meta';
import GetStartedCenter from '../components/GetStartedCenter';

import Template from '../components/Layout/Layout';
// import { CustomerStoriesHero } from '../styles/customer-stories-styles';
import 'styles/blogPost.scss';
import 'styles/customer-stories-styles.scss';
const CustomerStoriesSingle = ({ pageContext: { post } }, { location }) => (
  <Template location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title={`${post.title} | Customer Stories | ruttl`}
          description="Improve your knowledge and stay updated with new trends in designing with ruttl's blog page that is regularly updated with latest content on web designing."
          url="https://ruttl.com/product-updates/"
        />
        <div className="customer-stories-hero">
          <div className="container">
            <div className="flex align-items-center">
              <div className="flex-1">
                <div className="flex">
                  <span className="tag">{post.tag}</span>
                </div>
                <h1>{post.title}</h1>
                <p>{post.excerpt}</p>
              </div>

              <div className="flex-1">
                <div className="hero-video-iframe">
                  <iframe
                    src={post.youtubeLink.replace('watch?v=', 'embed/')}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="blog-post-styled-main customer-stories-content">
          <div className="container">
            <div className="blog-wrapper content-wrapper">
              <div className="entry-content">
                {documentToReactComponents(post.content.json, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => {
                      if (
                        typeof children?.[0] === 'string' &&
                        children[0].startsWith('<iframe')
                      )
                        return (
                          // eslint-disable-next-line react/no-danger
                          <div dangerouslySetInnerHTML={{ __html: children }} />
                        );
                      return <p>{children}</p>;
                    },
                  },
                })}
              </div>

              {/* <HostsBlock>
                <div className="flex align-items-center">
                  <div className="host-img">
                    <img src="/assets/img/craig-hooper.jpeg" alt="" />
                  </div>
                  <div className="hosts-desc flex-1">
                    <h3>Lorem Ipsum</h3>
                    <h4>CEO Lorem ipsum INC</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero,
                      aliquam.
                    </p>
                  </div>
                </div>
              </HostsBlock> */}
            </div>
          </div>
        </main>
        <GetStartedCenter toggleSignup={toggleSignup} />
      </>
    )}
  </Template>
);

CustomerStoriesSingle.propTypes = {
  pageContext: PropTypes.shape({
    post: PropTypes.shape({
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
      youtubeLink: PropTypes.string.isRequired,
      content: PropTypes.shape({
        json: PropTypes.object,
      }),
    }),
  }),
};

export default CustomerStoriesSingle;
